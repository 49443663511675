import styled from 'styled-components';

export const ErrorAlert = styled.p`
	background: #fff;
	color: #fc5d5a;
	font-weight: 400;
	font-size: 14px;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 20px;
	padding-bottom: 20px;
	border-radius: 6px;
`;

export const ErrorMsg = styled.p`
	color: #000;
	font-size: 12px;
	margin: 0;
`;

export const ErrorView = styled.p`
	font-size: 12px;
	margin-top: 10px;
	padding: 10px;
	border-radius: 6px;
	background: rgba(255, 255, 255, 100%);
	color: #ff0000;
`;
