import React from 'react';
import { Box, TextInput, Button } from 'grommet';
import styled from 'styled-components';
import { ShowPassIcon, HidePassIcon } from '../icons';

const PasswordInput = ({ name, required, onChange }) => {
	const [reveal, setReveal] = React.useState(false);

	return (
		<PasswordWrap width="full" direction="row" align="center">
			<TextInputStyled
				name={name}
				required={required}
				onChange={onChange}
				plain
				type={reveal ? 'text' : 'password'}
				aria-label="Input Password"
				focusIndicator={false}
			/>
			<IconButton
				primary
				icon={
					reveal ? <ShowPassIcon width="18px" /> : <HidePassIcon width="18px" />
				}
				onClick={() => setReveal(!reveal)}
			/>
		</PasswordWrap>
	);
};

export default PasswordInput;

export const PasswordWrap = styled(Box)`
	width: 100%;
	font-size: 14px;
	margin-top: 2px;
	border-radius: 6px;
	background: rgba(255, 255, 255, 0.8);
	border: 1px solid #cccccc;
	box-sizing: border-box;
	outline: none;
	padding: 0;
	height: 35px;
`;

export const TextInputStyled = styled(TextInput)`
	width: 100%;
	font-size: 12px;
	height: 32px;
	background: transparent;
	color: ${(props) => (props.readOnly ? '#888888' : '#000000')};

	&:focus {
		background: ${(props) => (props.readOnly ? '#E6E6E6' : 'white')};
		border: none;
	}

	&:disabled {
		background: gba(255, 255, 255, 0.55);
		cursor: not-allowed;
		opacity: 0.8;
	}

	&::placeholder {
		color: #a0a3b0;
	}
`;

export const IconButton = styled(Button)`
	display: flex;
	width: 40px;
	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0;
	border-left: 1px solid #cccccc;
	border-radius: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;
