/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Anchor, Box, Button, Grid, Image, Page, Select, Text } from 'grommet';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useMakeActiveUserCenterMutation } from '@/apollo/gql/generated/graphql';
import ValidClasses from '@/components/valid-classes';
import CookieService from '@/services/cookies';
import { useViewerContext } from '@/contexts/useViewer';

const JoinClassView = () => {
	const [makeActiveUserCenter, { data: loginData }] =
		useMakeActiveUserCenterMutation();
	const { t } = useTranslation('default');
	const [availableClasses, setAvailableClasses] = useState([]);
	const [currentUser, setCurrentUser] = useState();
	const [selectedClass, setSelectedClass] = useState();
	const [showAlert, setShowAlert] = useState(false);

	const handleJoinClass = useCallback(
		(e) => {
			const nextClass = selectedClass || availableClasses[0]?.slug;

			if (selectedClass) {
				const classCenter = availableClasses.find(
					(aClass) => aClass.slug === selectedClass
				);
				makeActiveUserCenter({
					variables: {
						id: currentUser?.id,
						centerCode: classCenter?.centerCode,
					},
					onError: (error) => {
						console.log(error);
					},
				});
			} else if (currentUser?.role !== 'student') {
				window.location.href = `/session/${nextClass}`;
			} else {
				window.location.href = `/session/${nextClass}/lobby`;
			}
		},
		[
			availableClasses,
			currentUser?.id,
			currentUser?.role,
			makeActiveUserCenter,
			selectedClass,
		]
	);

	const doLogout = async () => {
		CookieService.set('granted_access', '', {
			path: '/',
		});
		CookieService.set('access_token', '', {
			path: '/',
		});
		localStorage.removeItem('pinnedUsers');
		CookieService.remove('access_token', { path: '/' });
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		window.location.href = `/dashboard`;
	};

	useEffect(() => {
		if (!loginData?.makeActiveUserCenter) return;
		const options = { path: '/' };
		CookieService.set(
			'access_token',
			loginData?.makeActiveUserCenter?.token as string,
			options
		);
		localStorage.setItem('token', loginData?.makeActiveUserCenter?.token || '');
		localStorage.setItem(
			'user',
			JSON.stringify(loginData?.makeActiveUserCenter)
		);

		if (currentUser?.role !== 'student') {
			window.location.href = `/session/${selectedClass}`;
		} else {
			window.location.href = `/session/${selectedClass}/lobby`;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginData]);

	useEffect(() => {
		const savedUser = localStorage.getItem('user');
		if (savedUser) {
			const user = JSON.parse(savedUser);
			const { availableClasses: userClasses, ...rest } = user;
			setCurrentUser(rest);
			setAvailableClasses(userClasses);
		}
	}, []);

	const classOptions = useMemo(
		() =>
			availableClasses?.map((aClass) => ({
				label: aClass?.name,
				value: aClass?.slug,
			})),
		[availableClasses]
	);

	return (
		<Page background="light-3" kind="full" height="100%">
			<Grid
				fill
				rows={['flex', 'flex']}
				columns={['flex', 'flex']}
				gap="none"
				areas={[
					{ name: 'logo', start: [0, 0], end: [0, 1] },
					{ name: 'login', start: [1, 0], end: [1, 1] },
				]}
			>
				<Box
					gridArea="logo"
					background="url(/images/common/Patterns-numbers.jpg)"
					pad="medium"
					align="center"
					justify="center"
				>
					<Image
						alt="logo"
						fit="contain"
						width="70%"
						src="/images/common/@home_all-white_min.png"
					/>
				</Box>
				<Box
					gridArea="login"
					pad="medium"
					align="center"
					justify="center"
					background="url(/images/common/pattern-numbers-gray-min.jpg)"
				>
					<Box
						align="center"
						background="rgba(255, 255, 255, 0.5)"
						round="small"
						border={{ color: 'light-4', size: '1px', style: 'solid' }}
						pad="large"
					>
						<Text size="large" weight="bold">
							{`${t('welcome_back', { name: currentUser?.firstName })}`}
						</Text>
						<Box>
							{(availableClasses || []).length === 1 && (
								<Text>{`${t('join_class', {
									class: availableClasses[0].name,
								})}`}</Text>
							)}
							{(availableClasses || []).length !== 1 && (
								<Box fill align="start" justify="start" pad="none">
									<Text margin="small">{t('pick_class_to_join')}</Text>
									<Select
										name="selectedClass"
										labelKey="label"
										valueKey={{ key: 'value', reduce: true }}
										options={classOptions}
										onChange={({ value: nextValue }) =>
											setSelectedClass(nextValue)
										}
									/>
								</Box>
							)}
							<Button
								primary
								style={{ marginTop: 16, borderRadius: '6px' }}
								size="medium"
								label={t('join_class_now')}
								onClick={handleJoinClass}
							/>

							<Anchor
								role="button"
								style={{
									cursor: 'pointer',
									color: 'red',
									margin: '0',
									paddingTop: '16px',
								}}
								onClick={doLogout}
								label={t('not_you')}
							/>
						</Box>
					</Box>
				</Box>
			</Grid>
			<ValidClasses
				classes={availableClasses}
				showAlert={showAlert}
				setShowAlert={setShowAlert}
			/>
		</Page>
	);
};

export default JoinClassView;
