import React, { useEffect, useState } from 'react';
import { Layer, Box, Text, Button } from 'grommet';

import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';

import CookieService from '@/services/cookies';

interface Props {
  classes: Array<string>;
  showAlert: boolean;
  setShowAlert: (show: boolean) => void;
}

const ValidClasses = ({ classes, showAlert, setShowAlert }: Props) => {
  const { t } = useTranslation('default');

  useEffect(() => {
    if (!classes) {
      setShowAlert(true);
      forcedLogout();
    }
  }, [classes]);

  const forcedLogout = async () => {
    CookieService.set('granted_access', '', {
			path: '/',
		});
		CookieService.set('access_token', '', {
			path: '/',
		});
		await CookieService.remove('access_token');
		await localStorage.removeItem('token');
		await localStorage.removeItem('user');
  };

  const handleClose = () => {
    setShowAlert(false);
    window.location.href = '/';
  };

  return (showAlert ? (
    <Layer>
      <MessageBox pad="large" style={{ minWidth: 500, backgroundColor: 'white' }}>
        <MessageBoxHeader>
          <MessageBoxHeaderText>
            <b>{t('invalidClassesAlert.title')}</b>
          </MessageBoxHeaderText>
        </MessageBoxHeader>

        <MessageBoxBody>
          <Text>{t('invalidClassesAlert.description')}</Text>
        </MessageBoxBody>

        <MessageBoxFooter>
          <ButtonStyled
            onClick={handleClose}
            label={t('invalidClassesAlert.close')}
            name="close-broadcast-button"
          />
        </MessageBoxFooter>
      </MessageBox>
    </Layer>
  ) : null);
};

export default ValidClasses;


export const MessageBox = styled(Box)``;
export const MessageBoxHeader = styled(Box)`
	font-style: italic;

	b {
		font-style: normal;
		font-weight: 600;
	}
`;

export const MessageBoxHeaderText = styled(Text)`
	display: flex;
	align-items: center;
	align-content: center;
  color: black;
`;

export const MessageBoxBody = styled(Box)`
	background: white;
	margin: 15px 0;
  color: black;
`;

export const MessageBoxFooter = styled(Box)`
	margin-top: 25px;
`;

export const ButtonStyled = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ef3e33;
	border: none;

  &:hover {
    border: none;
    box-shadow: none;
  }
`;