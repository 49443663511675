import styled from 'styled-components';

export const TextInput = styled.input`
	width: 100%;
	font-size: 14px;
	padding: 8px 10px;
	margin-top: 2px;
	border-radius: 6px;
	background: white;
	background: ${(props) =>
		props.readOnly ? '#E6E6E6' : 'rgba(255, 255, 255, 0.8)'};
	color: ${(props) => (props.readOnly ? '#888888' : '#000000')};
	border: 1px solid #cccccc;
	box-sizing: border-box;
	outline: none;

	&.date {
		padding: 6px 10px;
	}

	&:focus {
		background: ${(props) => (props.readOnly ? '#E6E6E6' : 'white')};
	}

	&:disabled {
		background: gba(255, 255, 255, 0.55);
		cursor: not-allowed;
		opacity: 0.8;
	}

	&::placeholder {
		color: #a0a3b0;
	}
`;

export const InputLabel = styled.label`
	color: #161515;
	display: block;
	padding-bottom: 5px;
	padding-top: 10px;
	font-size: 14px;

	${TextInput} {
		margin-top: 3px;
	}
`;

interface ButtonProps {
	primaryButtonBgColor?: string;
	primaryButtonColor?: string;
}

export const PrimaryButton = styled.button<ButtonProps>`
	background: ${(props) => props.primaryButtonBgColor};
	color: ${(props) => props.primaryButtonColor};
	width: '100%';
	border-radius: 4px;
	padding: 10px 10px;
	cursor: pointer;
	border: none;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:disabled {
		opacity: 65%;
		cursor: not-allowed;
	}
`;
