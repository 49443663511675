import CookieService from '@/services/cookies';
import { FC } from 'react';
import JoinClassView from './joinClass.view';
import LoginView from './login.view';

type Props = {
	containerIp: string | null;
};
const LoginContainer: FC<Props> = ({ containerIp }: Props) => {
	const user = localStorage.getItem('user');
	const userToken = CookieService.get('access_token');

	if (!user || !userToken) {
		return <LoginView containerIp={containerIp} />;
	}

	return <JoinClassView />;
};
export default LoginContainer;
