import LoggingProvider from '@/contexts/useLogging';
import LoginContainer from '@/modules/class-room/application/login/login.container';
import ViewerProvider from '@/contexts/useViewer';
import type { NextPage } from 'next';
import Head from 'next/head';

export async function getServerSideProps(context: any) {
	const containerIp = process.env.CONTAINER_IP || null;
	const loggingServerUrl = process.env.LOGGING_SERVICE_URL || null;

	return {
		props: {
			containerIp,
			loggingServerUrl,
		},
	};
}

type HomeProps = {
	containerIp: string;
	loggingServerUrl?: string;
};

const Home: NextPage<HomeProps> = ({
	containerIp,
	loggingServerUrl,
}: {
	containerIp?: string;
	loggingServerUrl?: string;
}) => (
	<>
		<Head>
			<title>Mathnasium Classroom</title>
			<meta charSet="UTF-8" />
			<meta
				name="description"
				content="Looking for a math tutor, Mathnasium provides a customized math learning program where math makes sense to kids. Get an expert tutor now!"
			/>
			<meta name="viewport" content="width=device-width, initial-scale=0.8" />
			<link rel="icon" href="/favicon.ico" />
		</Head>
		<ViewerProvider>
			<LoggingProvider
				containerIpAddress={containerIp}
				loggingServerUrlAddress={loggingServerUrl as string}
			>
				<LoginContainer containerIp={containerIp as string | null} />
			</LoggingProvider>
		</ViewerProvider>
	</>
);

export default Home;
